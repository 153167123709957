/* font import */
@import url('https://fonts.cdnfonts.com/css/celtic-md');


@keyframes modalIn {
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  }
  
  h2 {
    font-family: 'Celticmd', sans-serif;
  }

#modal {
  text-align: center;
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size: 15px;
  z-index: 1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  width: 500px;
  margin: 0 auto;
  color: green;
  opacity: 0.92;
}

#modalContent {
  border-radius: 3px;
  background-color: white;
  padding: 1rem;
  width: 100%;
  position: relative;
  animation-name: modalIn;
  animation-duration: 1s;
}

.contactHim {
  font-style: oblique;
  margin-right: 10%;
  margin-left: 30%;
  text-align: end;
}

.Icon {
  color: green;
  transition: 0.5s ease;
}

.Icon:hover {
  font-size: 25px;
} 

#instaIcon {
  margin-left: 3px;
}

#faceIcon {
  margin-left: 3px;
}

@media only screen and (max-width: 750px) {
  #modal {
      width: 75% !important;
      height: 50% !important;
      font-size: 13px !important;
      background-color: transparent !important;
     }
   
     .modal-content {
       right: 2% !important;
       border-radius: 3px !important;
     }
}