  /* event tab styling */

table {
    text-align: center;
    overflow-x: auto;
    width: 20% !important;
}

.programmed{
  margin-top: 90px;
}

tr {
  color: white;
  transition: 0.5s ease;
}


tr:hover {
  color: green;
}

/* responsivity */
@media only screen and (max-width: 750px) {
    .event-container {
        position: relative;
        top: 40px;
      }
    
      table {
        position: relative;
        font-size: 14px;
        width: 50% !important;
      }
}
  