.background  {
    background-size: cover;
    background-position:center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    background-repeat: 'no-repeat';
}
