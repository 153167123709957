
/* nav styling */
.navbar {
    padding: 10px !important;
    box-shadow: inset !important;
    font-family: Crimson Text;
    font-weight: 300;
    letter-spacing: 3px;
  }

 .navbar-brand {
  position: relative;
 }


 #logoImg {
  width: 40px;
  /* height: 50px; */
 }

 #logoTitle{
  width: 110px;
  position: relative;
  top:5px;
  margin-left: 6px;
 }

 .ourEvents, .aboutUs, .contactUs {
  cursor: pointer;
  color: green;
  font-size: 12px;
  transition:0.5s ease;
  font-family: 'Celticmd', sans-serif;
 }

 .ourEvents:hover, .aboutUs:hover, .contactUs:hover{
  color: darkgreen;
  font-size: 18px;
 }

 .dropDown {
  display: none;
 }

 .navbar-toggle {
  color: transparent;
  background-color: transparent;
 }

 .group-info-container {
  position: absolute;
  margin-top: 6rem;
  width: 100%;
  text-align: center;
 }

 #programEventId {
  position: relative;
  top: 30px;
  margin-top: 80px;
  margin-left: 40%;
  margin-right: 40%;
  color: green;
  text-align: center;
  font-family: 'Celticmd', sans-serif;
  font-size: 12px;
}

 #comingSoonId {
  position: relative;
  top: -20px;
  margin-top: 120px;
  margin-left: 40%;
  margin-right: 40%;
  text-align: center;
  border-radius: 5px;
  /* display: 'none'; */
  font-family: 'Celticmd', sans-serif;
  font-size: 12px;
}

/* responsivity */
@media only screen and (max-width: 750px) {
  #logoutButton, #goAddEventButton {
    display: none;
  }
  .navbar-toggle{
    margin-right: 30px !important;
  }

  .navbar-toggler-icon {
    width:20px !important;
  }

  .instagram, .facebook {
    display: none;
  }

  .dropDown {
    display:flex !important;
  }

  #dropdownId {
    position: absolute !important;
    top: 30px !important;
    margin-top: 45px;
    right: 0.5%;
    text-align: center;
    padding: 20px !important;
  }



  .responsiveEvents, .responsiveAboutUs, .responsiveContactUs{
    color: green;
    font-family: 'Celticmd', sans-serif;
    font-size: 10px !important;
    transition:  0.5s ease;
    cursor: pointer;
  }

  #responsiveLogout, #responsiveAddEvent {
    position: relative;
    top: 10px;
    color: green;
    transition:0.5s ease;
    padding: 5px;
  }

  #responsiveLogout:hover{
    color: black;
    background-color: green;
  }

  #responsiveAddEvent:hover{
    color: black;
    background-color: green;
  }


  .responsiveEvents:hover {
   color: black;
   background-color: green;
  }

  .responsiveAboutUs:hover {
    color: black;
    background-color: green;
  }

  .responsiveContactUs:hover{
    color: black;
    background-color: green;
  }

  .contactUs, .aboutUs, .ourEvents {
    display: none;
  }

  .button {
    display:none
  }


  #comingSoonId {
    position: relative !important;
    left: 10px !important;
    width: 30% !important;
    margin-left: 32% !important;
  }

  #programEventId {
    width: 40% !important;
    margin-left: 30% !important;
  }

  #pastEventId {
    width: 30% !important;
    margin-left: 35.5% !important;
  }

  .Comingsoontext, .Programmatitext, .Conclusitext {
    position: relative;
    top:2px;
  }
}