.instaIcon, .faceIcon {
    color:#3c843c;
    transition: 0.5s ease;
  }
  
  .instaIcon:hover, .faceIcon:hover {
    color: #3e873e;
    font-size:55px
  }

  .contacts {
    display: block;
    text-align: center !important;
  }

  #instaLink, #faceLink {
    margin-top: 60px;
    width: 30%;
    border-radius: 15px;
  }

  .instaRow, .faceRow {
    font-size: 50px;
    border-radius: 20px;
    margin: 40%;
  }

/* responsivity */
@media only screen and (max-width:750px) {
    .instaRow, .faceRow {
        width: 80%;
        position: relative;
        top:40px;
        right: 17%;
        margin-top: 100px !important;
    }
}