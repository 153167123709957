.fontInstagram, .fontFacebook, .fontEmailBox {
    margin-right: 10px;
    color: green;
    transition: 0.5s ease;
}

.fontInstagram:hover, .fontFacebook:hover, .fontEmailBox:hover {
    color: darkgreen;
    font-size: 30px;
}

@media only screen and (max-width: 750px) {
    .buttons {
        position: relative;
        top: 5px;
        margin-left: 7px;
    }
}

