label {
    font-family: 'Celticmd', sans-serif;
    /* color : #FF883E; */
}

form {
    background-color: #169B62;
}

#navigateButton, #showFormButton {
    font-family: 'Celticmd';
    background-color: #198754;
}

#navigateButton:hover, #showFormButton:hover {
    background-color: #167247 !important;
}


@media only screen and (max-width: 750px) {
    .loading-container {
        margin-left: 15px !important;
    }
}

