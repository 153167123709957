
.group-info {
    display:'flex';
    padding: 25px;
    border-radius: 30px;
    margin-right: 2%;
    margin-left: 2%;
  }
  
  .title, .first-row, .second-row, .third-row, .fourth-row, .fifth-row, .greeting{
    color: RGB(25, 135, 84);
  }

  .imgGroup {
    position:relative;
    height:'20rem';
    width:'20rem';
  }
  
  @media only screen and (max-width: 750px) {
    .group-info {
        position: absolute;
        margin-left: 18%;
        width: 65%;
      }

    .title, .first-row, .second-row, .third-row, .fourth-row, .fifth-row, .greeting {
        color: RGB(25, 135, 84);
        font-size: 10px;
      }
   .imgGroup {
      display: none !important;
    }

  }