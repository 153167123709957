#pastEventId {
  position: relative;
  top: 30px;
  margin-top: 80px;
  margin-left: 40%;
  margin-right: 40%;
  color:green;
  text-align: center;
  font-family: 'Celticmd', sans-serif;
  font-size: 12px;
}

#pasteventTabId{
  margin-top: 90px;
  position: relative;
  background-color: #212529;
  top: -20px;
}

#imgContainerId {
  position:relative;
}

.secondImage {
  position: relative;
  float: right;
}

#imgContainer2Id {
  position:relative;
  margin-top:20px;
}

.firstImage, .secondImage, .thirdImage, .fourthImage {
  transition: 1s;
}

.firstImage:hover, .secondImage:hover, .thirdImage:hover, .fourthImage:hover {
  filter: brightness(75%);
}

@media only screen and (max-width: 750px) {
    .firstImage, .secondImage {
        width: 80%;
        float: none !important;
      }
    
      .secondImage {
        margin-top: 20px;
      }
      
     .images {
      text-align: center;
     }
}