
.col {
    display:flex;
    margin-top: 80px !important;
    margin-right: 80px;
}

.card {
    background-color: rgb(8, 8, 8) !important;
    text-align: center;
    margin-top:3px;
    margin-bottom: 8px;
    color: green;
    font-family: 'Celticmd', sans-serif;
    font-size: 9px;
    width: 18rem;
    transition:0.5s ease;
}

.card:hover {
    width: 18.5rem;
}

/* responsivity */
@media only screen and (max-width: 750px) {
   .card-container{
    padding-left: 12.5%;
   }
}
  
