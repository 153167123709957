body {
    background-color: black !important;
}

#spinnerSpace {
    flex: 1;
    margin-top:240px;
    justify-content: 'center';
    align-items:'center';
    font-family: 'Celticmd', sans-serif;
}
