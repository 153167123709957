/* footer styling & animation*/

.footer-content {
    position: fixed;
    font-size: 6px;
    left: 0;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    transition:  1s ease-in-out;
  }
  
  .footer-content:hover{
    font-size: 12px;
  }
  
  